import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Text,
    SimpleGrid,
    useColorModeValue,
    Container,
    Icon,
} from '@chakra-ui/react';
import Footer from '../components/Footer';
import Button from '../ui/button/Button';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { FaQuestionCircle, FaEdit, FaRegLightbulb } from 'react-icons/fa';

export const LandingPage: React.FC = () => {
    // Define a reusable animation style
    const animatedBoxStyle = {
        p: 6,
        textAlign: 'center' as const, // Ensure correct typing for textAlign
        bg: useColorModeValue('white', 'gray.800'),
        borderRadius: 'md',
        boxShadow: 'md',
        transition: 'all 0.3s ease',
        _hover: {
            transform: 'translateY(-6px)',
            boxShadow: 'xl',
        },
    };

    // Conditionally set the hero image based on color mode
    const image_1 = useColorModeValue(
        "url('/images/dashboard_1_light.png')",
        "url('/images/dashboard_1_dark.png')"
    );

    return (
        <Box bg="background" color="text">
            {/* Hero Section */}
            <Box
                as="section"
                bgGradient={useColorModeValue(
                    'linear(to-r, light.primary.100, light.primary.50)',
                    'linear(to-r, dark.primary.800, dark.primary.900)'
                )}
                py={{ base: 12, md: 20 }}
                px={8}
            >
                <Container maxW="7xl">
                    <Flex
                        direction={{ base: 'column', md: 'row' }}
                        align="center"
                        justify="space-between"
                        gap={8}
                    >
                        <Box flex="1" textAlign={{ base: 'center', md: 'left' }}>
                            <Heading size="2xl" mb={4}>
                                Welcome to CasPrep, The Ultimate Learning Platform for Casper Preparation
                            </Heading>
                            <Text fontSize="xl" mb={6}>
                                Access to 175+ questions, powerful analytics, and AI-enhanced feedback.
                            </Text>
                            <Button
                                variant="primary"
                                withRightArrow
                                onClick={() =>
                                    window.open(process.env.REACT_APP_DASHBOARD_SITE_ADDRESS, '_blank')
                                }
                            >
                                Get Started
                            </Button>
                        </Box>

                        <Box
                            flex="1"
                            display={{ base: 'none', md: 'block' }}
                            bgImage={image_1}
                            bgSize="contain"
                            bgPosition="center"
                            bgRepeat="no-repeat"
                            minH="300px"
                            borderRadius="md"
                        />
                    </Flex>
                </Container>
            </Box>

            {/* Features Section */}
            <Box as="section" py={12} px={8}>
                <Container maxW="6xl">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                        <Box {...animatedBoxStyle}>
                            <Heading size="md" mb={4}>
                                ⚡ Expansive Question Bank
                            </Heading>
                            <Text>
                                Over 175 questions in many different categories such as ethical dilemmas,
                                personal questions, professionalism, and more!
                            </Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <Heading size="md" mb={4}>
                                📝 AI-enhanced Feedback
                            </Heading>
                            <Text>
                                Trained AI that provides you with detailed, personalized feedback that
                                helps you identify what you are doing correctly and what you can improve on!
                            </Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <Heading size="md" mb={4}>
                                📖 Powerful Analytics
                            </Heading>
                            <Text>
                                Keep track of your progress as you practice with the dynamic dashboard
                                with metrics such as typing speed, average answer quartile score, top
                                hit-on and missed characteristics, and more!
                            </Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* Cheaper Alternative Section */}
            <Box
                as="section"
                bg={useColorModeValue('light.primary.50', 'dark.primary.900')}
                py={12}
                px={8}
            >
                <Container maxW="md" textAlign="center">
                    <Heading size="lg" mb={4}>
                        📉 Cheaper Alternative
                    </Heading>
                    <Text fontSize="lg">
                        Plans that are much more affordable, personalized, and equal to
                        well-known competitor products.
                    </Text>
                </Container>
            </Box>

            {/* What You Get Section */}
            <Box
                as="section"
                bg={useColorModeValue('light.primary.50', 'dark.primary.900')}
                py={12}
                px={8}
            >
                <Container maxW="4xl">
                    <Heading textAlign="center" mb={6}>
                        What You Get
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                        <Box {...animatedBoxStyle}>
                            <CheckCircleIcon color="green.400" boxSize={6} mb={4} />
                            <Text fontSize="lg">175+ questions that are Casper representative</Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <CheckCircleIcon color="green.400" boxSize={6} mb={4} />
                            <Text fontSize="lg">Powerful analytics to track your progress</Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <CheckCircleIcon color="green.400" boxSize={6} mb={4} />
                            <Text fontSize="lg">
                                AI-enhanced feedback for continuous improvement
                            </Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <CheckCircleIcon color="green.400" boxSize={6} mb={4} />
                            <Text fontSize="lg">
                                Top-notch 24/7 dedicated customer service support
                            </Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <CheckCircleIcon color="green.400" boxSize={6} mb={4} />
                            <Text fontSize="lg">No credit card required to get started</Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>

            {/* See How It Works Section */}
            <Box as="section" py={12} px={8}>
                <Container maxW="3xl">
                    <Heading textAlign="center" mb={8}>
                        See How It Works
                    </Heading>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                        <Box {...animatedBoxStyle}>
                            <Icon
                                as={FaQuestionCircle}
                                boxSize={8}
                                mb={4}
                                color={useColorModeValue('black', 'white')}
                            />
                            <Text>Step 1: Pick a question from our question bank</Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <Icon
                                as={FaEdit}
                                boxSize={8}
                                mb={4}
                                color={useColorModeValue('black', 'white')}
                            />
                            <Text>Step 2: Fill in your answer</Text>
                        </Box>

                        <Box {...animatedBoxStyle}>
                            <Icon
                                as={FaRegLightbulb}
                                boxSize={8}
                                mb={4}
                                color={useColorModeValue('black', 'white')}
                            />
                            <Text>Step 3: View your AI-enhanced feedback</Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>
        </Box>
    );
};