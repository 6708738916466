import {Image, Text} from "@chakra-ui/react";
import {WEB_SITE_TITLE} from "./SidebarWithHeader";


const CasPrepLogo = () => {
    return (
        <>
            <CasPrepLogoImage/>
            <CasPrepLogoText/>
        </>
    )
}

export const CasPrepLogoText = () => {
    return (
        <Text fontSize="xl" fontWeight="bold" color={"black"} fontFamily="Orbitron">
            {WEB_SITE_TITLE}
        </Text>
    )
}

export const CasPrepLogoImage = () => {
    return (
        <Image
            src="/logo1.svg"
            alt="Logo"
            boxSize="30px"
            ml={2}
            marginRight={"5px"}
            borderRadius="full"
        />
    )
}

export default CasPrepLogo