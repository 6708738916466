const colors = {
    primary: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        hover: 'primary.700',
        focus: 'primary.600',
        active: 'primary.800',
        disabled: 'primary.200',
    }, // Primary brand colors for main elements
    secondary: {
        50: '#FFF8E1',
        100: '#FFECB3',
        200: '#FFE082',
        300: '#FFD54F',
        400: '#FFC107',
        500: '#FFA000',
        600: '#FF8F00',
        700: '#FF6F00',
        800: '#E65100',
        900: '#BF360C',
        hover: 'secondary.700',
        focus: 'secondary.600',
        active: 'secondary.800',
        disabled: 'secondary.200',
    }, // Secondary colors for accents and highlights
    neutral: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        hover: 'neutral.700',
        focus: 'neutral.600',
        active: 'neutral.800',
        disabled: 'neutral.200',
    }, // Neutral colors for backgrounds, borders, and text
};

export default colors