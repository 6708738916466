import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Text,
    VStack,
    useColorModeValue,
    CircularProgress,
    CircularProgressLabel,
    Collapse,
    Spinner,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { CasperInteraction, CasperScenario } from "../../models/Models";
import { CasperAPI } from "../../APIs/CasperAPI";
import CasperInteractionComp from "../../components/CasperInteractionComp";

interface InteractionHistoryItem {
    interaction: CasperInteraction;
    scenario: CasperScenario;
    isExpanded: boolean;
}

const InteractionHistory: React.FC = () => {
    const [interactions, setInteractions] = useState<InteractionHistoryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const boxBg = useColorModeValue('background', 'background');
    const responseBg = useColorModeValue('primary.50', 'primary.800');
    const borderColor = useColorModeValue('primary.200', 'primary.700');

    useEffect(() => {
        const fetchInteractions = async () => {
            setLoading(true);
            setError(null);
            try {
                // Fetch the list of interactions
                const interactionsList: CasperInteraction[] =
                    await CasperAPI.get_self_interactions();

                // Fetch the scenarios for each interaction
                const interactionsWithScenarios = await Promise.all(
                    interactionsList.map(async (interaction, index) => {
                        const scenario: CasperScenario =
                            await CasperAPI.get_casper_scenario(interaction.casper_scenario.toHexString());
                        return {
                            interaction,
                            scenario,
                            isExpanded: false,
                        };
                    })
                );

                setInteractions(interactionsWithScenarios);
            } catch (err) {
                console.error("Error fetching interactions:", err);
                setError("An error occurred while fetching interactions.");
            }
            setLoading(false);
        };

        fetchInteractions();
    }, []);

    const toggleExpand = (index: number) => {
        setInteractions((prevInteractions) =>
            prevInteractions.map((item, idx) => ({
                ...item,
                isExpanded: idx === index ? !item.isExpanded : false,
            }))
        );
    };

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <VStack spacing={6} align="stretch">
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Interaction History
                </Text>

                {loading ? (
                    <Spinner size="xl" alignSelf="center" />
                ) : error ? (
                    <Text color="red.500">{error}</Text>
                ) : interactions.length === 0 ? (
                    <Text>No interactions found.</Text>
                ) : (
                    interactions.map((item, index) => (
                        <Box
                            key={index}
                            p={4}
                            bg={responseBg}
                            borderRadius="md"
                            borderWidth="1px"
                            borderColor={borderColor}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Text fontSize="lg" fontWeight="bold">
                                    {item.scenario.scenario || `Scenario ${item.scenario._id}`}
                                </Text>
                                <Button size="md" minW={"120px"} onClick={() => toggleExpand(index)}>
                                    {item.isExpanded ? "Hide Details" : "View Details"}
                                </Button>
                            </Box>


                            <Text fontSize="sm" color="gray.500" mt={2}>
                                {formatDistanceToNow(new Date(item.interaction.created_at), {
                                    addSuffix: true,
                                })}
                            </Text>

                            {/* Display scores */}
                            <Box mt={4} display="flex" flexWrap="wrap" gap={4}>
                                {item.interaction.interaction_details.map((detail, idx) => (
                                    <Box
                                        key={idx}
                                        textAlign="center"
                                        width={{ base: "100%", sm: "48%", md: "23%" }}
                                    >
                                        <Text fontWeight="semibold" mb={2}>
                                            Question {idx + 1}
                                        </Text>
                                        <CircularProgress
                                            value={detail.score}
                                            max={4}
                                            size="60px"
                                            color="green.400"
                                            thickness="8px"
                                        >
                                            <CircularProgressLabel fontSize="md" fontWeight="bold">
                                                {detail.score}/4
                                            </CircularProgressLabel>
                                        </CircularProgress>
                                    </Box>
                                ))}
                                {
                                    item.interaction.time_elapsed && (
                                        <Box
                                            textAlign="center"
                                            width={{ base: "100%", sm: "48%", md: "23%" }}
                                        >
                                            <Text fontWeight="semibold" mb={2}>
                                                Seconds Elapsed
                                            </Text>
                                            <CircularProgress
                                                value={item.interaction.time_elapsed}
                                                max={300}
                                                size="60px"
                                                color="green.400"
                                                thickness="8px"
                                            >
                                                <CircularProgressLabel fontSize="md" fontWeight="bold">
                                                    {item.interaction.time_elapsed} S
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </Box>
                                    )
                                }
                            </Box>


                            {/* Expandable CasperResult */}
                            <Collapse in={item.isExpanded} animateOpacity>
                                <Box mt={4}>
                                    <CasperInteractionComp
                                        interaction={item.interaction}
                                        scenario={item.scenario}
                                    />
                                </Box>
                            </Collapse>
                        </Box>
                    ))
                )}
            </VStack>
        </Box>
    );
};

export default InteractionHistory;