// src/components/ProfileComponent.tsx

import React, { useState, useEffect } from "react";
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Spinner,
    Avatar,
    Badge,
    Stack,
    Heading,
    useToast,
    Flex,
    Card,
    CardBody,
    CardHeader,
    Divider,
    FormErrorMessage,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Select,
    useColorModeValue,
} from "@chakra-ui/react";
import { PhoneIcon, CheckIcon } from "@chakra-ui/icons";
import { User } from "../models/Models";
import { UsersAPI } from "../APIs/UsersAPI";
import { ObjectId } from "bson";
import { CustomToaster } from "./CustomToaster";

// North American phone number format regex (123-456-7890 or (123) 456-7890)
const northAmericanPhoneRegex = /^(\+1\s?)?(\(?\d{3}\)?[-.\s]?)\d{3}[-.\s]?\d{4}$/;

// Enum for gender options
export enum GenderEnum {
    Male = 'Male',
    Female = 'Female',
    RatherNotSay = 'Rather not say'
}

const ProfileComponent: React.FC<{ userId?: ObjectId; onClose?: () => void }> = ({ userId, onClose }) => {
    // **1. Hooks Called Unconditionally at the Top**
    const [user, setUser] = useState<Partial<User> | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [updatedUser, setUpdatedUser] = useState<Partial<User>>({});
    const [phoneError, setPhoneError] = useState<string | null>(null); // For phone number validation error
    const toast = useToast();

    // **2. Theme-Based Colors Using useColorModeValue**
    const primaryColor = useColorModeValue('primary', 'primary');
    const primaryHoverColor = useColorModeValue('primary.600', 'primary.600');
    const textColor = useColorModeValue('text', 'text');
    const backgroundColor = useColorModeValue('background', 'background');
    const inputBackground = useColorModeValue('primary.50', 'primary.800');
    const inputTextColor = useColorModeValue('text', 'text');
    const iconColor = useColorModeValue('primary.300', 'primary.300');
    const hoverBg = useColorModeValue('primary.50', 'primary.50');

    // **3. Fetch User Data with useEffect**
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = userId ? await UsersAPI.get_user(userId) : await UsersAPI.get_self();
                setUser(userData);
                setUpdatedUser(userData);
            } catch (error: any) {
                CustomToaster.error(error.title, error.description);
            } finally {
                setLoading(false);
            }
        };

        fetchUser().then();
    }, [userId, toast]);

    // **4. Handle Input Changes with Validation**
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        // Validate phone number
        if (name === "phone_number") {
            if (!northAmericanPhoneRegex.test(value)) {
                setPhoneError("Please enter a valid North American phone number.");
            } else {
                setPhoneError(null); // Clear error if valid
            }
        }

        setUpdatedUser((prevUser) => ({ ...prevUser, [name]: value }));
    };

    // **5. Handle Save Operation**
    const handleSave = async () => {
        if (phoneError) {
            toast({
                title: "Validation Error",
                description: "Please correct the phone number field before saving.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const updatePromise = UsersAPI.update_user_profile(updatedUser);

        CustomToaster.promise(updatePromise, {
            loading: { title: 'Updating Profile', description: 'Please wait while we update your profile.' },
            success: { title: 'Profile updated', description: 'Your profile has been successfully updated.' },
            error: { title: 'Error', description: 'Failed to update profile. Please try again.' },
        })
            .then(() => {
                setEditing(false);
                setUser(updatedUser); // Update the current user data with the new values
            })
            .catch((error) => {
                console.error('Profile update failed:', error);
            });
    };

    // **6. Early Return for Loading State**
    if (loading) {
        return (
            <Box textAlign="center" mt={8}>
                { onClose ?
                    <Button
                        size="sm"
                        variant="outline"
                        onClick={onClose}
                        mb={4}
                        borderColor={primaryColor}
                        color={primaryColor}
                        _hover={{bg: hoverBg}}
                    >
                        Back
                    </Button>
                    : <></>
                }
                <Spinner size="xl" />
            </Box>
        );
    }

    // **7. Main Render**
    return (
        <Box maxW="600px" mx="auto" mt={8} bg={backgroundColor} p={4} borderRadius="md" boxShadow="md">
            { onClose ?
                <Button
                    size="sm"
                    variant="outline"
                    onClick={onClose}
                    mb={4}
                    borderColor={primaryColor}
                    color={primaryColor}
                    _hover={{bg: hoverBg}}
                >
                    Back
                </Button>
                : <></>
            }
            {user ? (
                <Card bg={backgroundColor} border="none">
                    <CardHeader>
                        <Flex alignItems="center" justifyContent="center" direction="column">
                            <Avatar size="2xl" name={user.full_name} src={user.photo_url} mb={4} />
                            <Heading size="lg" color={textColor}>{user.full_name}</Heading>
                            <Badge colorScheme="success" mt={2}>
                                {user.email}
                            </Badge>
                        </Flex>
                    </CardHeader>
                    <Divider />
                    <CardBody>
                        <form>
                            <Stack spacing={4}>
                                <FormControl>
                                    <FormLabel color={textColor}>Full Name</FormLabel>
                                    <Input
                                        type="text"
                                        name="full_name"
                                        value={updatedUser.full_name || ""}
                                        onChange={handleInputChange}
                                        isDisabled={!editing}
                                        bg={inputBackground}
                                        color={inputTextColor}
                                    />
                                </FormControl>

                                <FormControl isInvalid={!!phoneError}>
                                    <FormLabel color={textColor}>Phone Number</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement pointerEvents="none">
                                            <PhoneIcon color={iconColor} />
                                        </InputLeftElement>
                                        <Input
                                            type="tel"
                                            name="phone_number"
                                            value={updatedUser.phone_number || ""}
                                            placeholder="Phone number"
                                            onChange={handleInputChange}
                                            isDisabled={!editing}
                                            bg={inputBackground}
                                            color={inputTextColor}
                                        />
                                        {!phoneError && updatedUser.phone_number && (
                                            <InputRightElement>
                                                <CheckIcon color="success" />
                                            </InputRightElement>
                                        )}
                                    </InputGroup>
                                    {phoneError && <FormErrorMessage>{phoneError}</FormErrorMessage>}
                                </FormControl>

                                <FormControl>
                                    <FormLabel color={textColor}>Casper Exam Date</FormLabel>
                                    <Input
                                        type="date"
                                        name="exam_date"
                                        value={updatedUser.exam_date ? new Date(updatedUser.exam_date).toISOString().substr(0, 10) : ""}
                                        onChange={handleInputChange}
                                        isDisabled={!editing}
                                        bg={inputBackground}
                                        color={inputTextColor}
                                    />
                                </FormControl>

                                {/*<FormControl>*/}
                                {/*    <FormLabel color={textColor}>Date of Birth</FormLabel>*/}
                                {/*    <Input*/}
                                {/*        type="date"*/}
                                {/*        name="dob"*/}
                                {/*        value={updatedUser.dob ? new Date(updatedUser.dob).toISOString().substr(0, 10) : ""}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        isDisabled={!editing}*/}
                                {/*        bg={inputBackground}*/}
                                {/*        color={inputTextColor}*/}
                                {/*    />*/}
                                {/*</FormControl>*/}

                                {/*<FormControl>*/}
                                {/*    <FormLabel color={textColor}>Gender</FormLabel>*/}
                                {/*    <Select*/}
                                {/*        name="gender"*/}
                                {/*        value={updatedUser.gender || ""}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        isDisabled={!editing}*/}
                                {/*        bg={inputBackground}*/}
                                {/*        color={inputTextColor}*/}
                                {/*    >*/}
                                {/*        {Object.values(GenderEnum).map((genderOption) => (*/}
                                {/*            <option key={genderOption} value={genderOption}>*/}
                                {/*                {genderOption}*/}
                                {/*            </option>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</FormControl>*/}
                            </Stack>

                            {editing ? (
                                <Button
                                    variant="solid"
                                    mt={6}
                                    onClick={handleSave}
                                    bg={primaryColor}
                                    _hover={{ bg: primaryHoverColor }}
                                >
                                    Save Changes
                                </Button>
                            ) : (
                                <Button
                                    variant="outline"
                                    mt={6}
                                    onClick={() => setEditing(true)}
                                    borderColor={primaryColor}
                                    color={primaryColor}
                                    _hover={{ bg: hoverBg }}
                                >
                                    Edit Profile
                                </Button>
                            )}
                        </form>
                    </CardBody>
                </Card>
            ) : (
                <Box textAlign="center" color={textColor}>No user profile found.</Box>
            )}
        </Box>
    );
};

export default ProfileComponent;